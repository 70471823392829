.sidebar {
    @apply fixed top-0 flex h-screen w-[104px] items-center justify-center flex-col border-r border-interface-300 bg-common-white pt-6;
}
.sidebarSmall .sidebar {
    @apply w-[56px];
}
.sidebarSmall .menuItem a span {
    @apply text-[0] p-[6px] justify-center;
}
.sidebarMenuList {
    @apply w-full p-2 pt-0;
}
.sidebarSmall .dropdown {
    @apply hidden;
}
.sidebarSmall .scrollable {
    @apply overflow-x-hidden;
}
.sidebarSmall .menuItem a i {
    @apply static mr-0;
}
.menuItem a span {
    @apply flex items-center mb-1 hover:bg-interface-100 hover:rounded-md h-[88px] font-medium justify-center flex-col text-center text-xs hover:text-common-black text-interface-600 uppercase;
}
/* .menuItem i {
    @apply hover:text-common-black !important;
} */
.menuItem a i {
    @apply text-base mb-1.5;
}
.menuItem li {
    @apply relative;
}
.menuItem li a {
    @apply flex flex-col;
}
.menuItem li .dropdown {
    @apply absolute right-4 top-4 text-[6px] text-interface-600;
}
.menuItem li.active a span {
    @apply font-medium rounded-md bg-primary-50 text-primary-500 hover:text-common-black hover:bg-interface-100;
}
.menuItem li.active .dropdown {
    @apply text-interface-900;
}
/* .menuItem li.active a span i {
    @apply text-primary-500 hover:text-common-black;
} */
.menuItem li .subMenu {
    @apply hidden;
}
.menuItem li.active .subMenu {
    @apply block;
}
.menuItem li .subMenu li a span {
    @apply ml-6 bg-transparent pl-[12px] text-interface-700;
}
.menuItem li .subMenu li.active a span {
    @apply font-medium text-interface-900;
}
/* .menuItem li.active .subMenu li a span.active {
    @apply bg-transparent text-primary-600;
} */
/* .menuItem li.active .subMenu {
    @apply relative;
} */
.menuItem li .subMenu::after {
    content: '';
    @apply absolute left-[13px] top-[6px] h-[90%] w-0.5 rounded-sm bg-body-bg;
}
.sidebarSmall .menuItem li .subMenu::after {
    @apply left-[19px];
}
