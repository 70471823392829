@tailwind base;
@tailwind components;
/* ================
    Custom
=================== */
html {
    @apply h-full font-normal;
}
body {
    @apply overflow-x-hidden text-sm font-normal bg-body-bg font-theme text-interface-900;
}
h1,
.h1,
h2,
.h2 {
    @apply mb-2 font-bold;
}
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @apply mb-2 font-semibold ipad:mb-1.5;
}
h1,
.h1 {
    @apply text-3xl ipad:text-2xl mobile:text-xl;
}
.h2,
h2 {
    @apply text-2xl ipad:text-xl mobile:text-base;
}
.h3,
h3 {
    @apply text-xl mobile:text-base;
}
.h4,
h4 {
    @apply text-lg;
}
.h5,
h5 {
    @apply text-base mobile:text-sm;
}
.h6,
h6 {
    @apply text-sm;
}
ul {
    @apply relative;
}
ol li {
    @apply text-sm list-decimal list-outside ml-4;
}
a {
    @apply text-primary-600;
}
.container {
    @apply mx-auto w-[calc(100%-88px)] max-w-[1080px] py-[42px] ipad:w-full ipad:px-6 ipad:py-[22px] mobile:w-full mobile:px-5 mobile:py-5;
}
.page-heading {
    @apply mb-2;
}
.separator {
    @apply my-6 h-[1px] w-full bg-interface-200;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#nprogress .bar {
    @apply bg-primary-500;
}
#nprogress .spinner {
    @apply hidden;
}
.scrollable,
.infinite-scroll-component {
    @apply overflow-auto;
}
.scrollable::-webkit-scrollbar-track,
.ck.ck-editor__editable_inline::-webkit-scrollbar-track,
.ql-snow.ql-snow .ql-editor.ql-editor::-webkit-scrollbar-track,
.infinite-scroll-component > div::-webkit-scrollbar-track,
.infinite-scroll-component::-webkit-scrollbar-track,
.style-table-two .base-table div::-webkit-scrollbar-track,
select::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.scrollable::-webkit-scrollbar,
.ql-snow.ql-snow .ql-editor.ql-editor::-webkit-scrollbar,
.ck.ck-editor__editable_inline::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar,
.infinite-scroll-component > div::-webkit-scrollbar,
.style-table-two .base-table div::-webkit-scrollbar,
select::-webkit-scrollbar {
    @apply h-[6px] w-[6px] rounded-[50px];
}
.scrollable::-webkit-scrollbar-thumb,
.ql-snow.ql-snow .ql-editor.ql-editor::-webkit-scrollbar-thumb,
.infinite-scroll-component::-webkit-scrollbar-thumb,
.ck.ck-editor__editable_inline::-webkit-scrollbar-thumb,
.infinite-scroll-component > div::-webkit-scrollbar-thumb,
.style-table-two .base-table div::-webkit-scrollbar-thumb,
select::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-300;
}
/* Custom Select */
.default-react-select .css-1s2u09g-control {
    @apply h-auto overflow-hidden text-sm font-normal border rounded-md border-interface-600 bg-interface-800 focus:border-interface-300 focus:shadow-none focus:outline-none;
}
.isHasError.default-react-select .css-1s2u09g-control {
    @apply border-danger-500;
}
.data-loader svg {
    @apply w-8 h-8;
}
/* phone field */
.PhoneInputCountry.PhoneInputCountry {
    @apply hidden;
}
.PhoneInputInput.PhoneInputInput,
.react-datepicker-ignore-onclickoutside {
    @apply focus:outline-none placeholder:text-interface-600;
}
.custom-date-time input,
.custom-date-time .react-datepicker-wrapper,
.custom-date-time .react-datepicker__input-container {
    @apply absolute top-0 left-0 right-0 w-full h-full px-3 border-none rounded-md outline-none bg-none text-interface-900 placeholder:text-interface-600;
}
.custom-date-time .date-picker-form-control input[disabled],
.custom-date-time .date-picker-form-control.disabled {
    @apply cursor-not-allowed hover:border-interface-300;
}
.custom-radio-field input,
.custom-checkbox-field input {
    @apply absolute opacity-0 cursor-pointer;
}
.fixed-label .custom-react-select__control {
    @apply hover:border-primary-500;
}
.form-disabled input,
.form-disabled .file-disabled,
.form-disabled button,
.form-disabled .custom-radio-field,
.form-disabled .custom__selectbox .custom-react-select__control {
    @apply opacity-50 pointer-events-none;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition:
        background-color 600000s 0s,
        color 600000s 0s;
}
.PhoneInput.PhoneInput--disabled input {
    @apply cursor-not-allowed text-interface-400 placeholder:text-interface-400 bg-interface-50;
}
/* =============
    Datepicker
=============== */
.custom-date-time .react-datepicker {
    @apply rounded-lg shadow border-interface-100 bg-common-white;
}
.react-datepicker__month-container {
    @apply relative p-1.5 pt-[80px] overflow-hidden;
}
.custom-date-time .react-datepicker__header {
    @apply absolute top-0 left-0 right-0 w-full h-full pt-3 mx-auto bg-transparent border-none pointer-events-none;
}
.custom-date-time
    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
    @apply pointer-events-auto;
}
.custom-date-time .react-datepicker__year-read-view--down-arrow,
.custom-date-time .react-datepicker__month-read-view--down-arrow,
.custom-date-time .react-datepicker__month-year-read-view--down-arrow,
.custom-date-time .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    @apply border-common-black hover:border-common-black;
}
.react-datepicker-popper {
    @apply !z-[2];
}
.custom-date-time .react-datepicker__year-option {
    @apply leading-3;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    @apply border-b-common-white;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    @apply border-b-interface-100;
}
.react-datepicker__current-month {
    transform: translate(-50%, -20%);
    @apply absolute left-[50%];
}
.react-datepicker__day-names {
    @apply relative mt-6 after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:h-[1px] after:w-[calc(100%-10px)] after:bg-interface-100 after:content-[''];
}
.custom-date-time .react-datepicker__day--keyboard-selected,
.custom-date-time .react-datepicker__month-text--keyboard-selected,
.custom-date-time .react-datepicker__quarter-text--keyboard-selected,
.custom-date-time .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day.react-datepicker__day--selected {
    @apply bg-primary-500 text-common-white hover:bg-interface-700;
}
.custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    @apply bg-interface-900 hover:bg-interface-600;
}
.custom-date-time .react-datepicker__day-name,
.custom-date-time .react-datepicker__day,
.custom-date-time .react-datepicker__time-name {
    @apply h-9 w-9 py-[5px];
}
.custom-date-time .icon-section {
    @apply absolute right-0 top-0 flex h-full items-center justify-center px-3.5;
}
.custom-date-time i {
    @apply text-lg text-interface-500;
}
.custom-date-time .react-datepicker__month-read-view {
    @apply absolute left-0;
}
.custom-date-time .react-datepicker__year-read-view {
    transform: translate(50%, -20%);
    @apply pointer-events-auto absolute left-0 top-[5px] h-9 w-1/2  bg-common-black opacity-0;
}
.custom-date-time
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll,
.custom-date-time
    .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
    @apply absolute top-0 left-0 right-0 w-full h-full mx-auto;
}
.custom-date-time .react-datepicker__year-dropdown {
    @apply pointer-events-auto absolute left-0 top-0 flex h-full w-full flex-row-reverse flex-wrap-reverse gap-[15px] border-none bg-common-white px-[44px] py-[54px];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option {
    @apply left-0 flex w-[25%] items-center justify-center border-none bg-common-white p-3 text-center;
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type,
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type {
    background-position: center;
    background-size: 28px auto;
    @apply absolute left-0 right-0 top-2 h-[40px] w-[94%] bg-interface-100 bg-no-repeat;
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type {
    @apply bg-[url('/storage/arrow-top.svg')];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type {
    @apply bottom-2 top-auto bg-[url('/storage/arrow-down.svg')];
}
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year {
    @apply rounded-md bg-primary-500 text-common-white;
}
.custom-date-time .react-datepicker__year-option--selected {
    @apply hidden;
}
.react-datepicker__day.react-datepicker__day--outside-month {
    @apply text-interface-300;
}
.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--outside-month {
    @apply text-common-white;
}
.booking-calendar-section .date-picker-form-control,
.booking-calendar-section select {
    @apply border-none h-9;
}
.filter-form .date-picker-form-control {
    @apply border-r-0 rounded-tr-none rounded-br-none;
}
.filter-end-date .date-picker-form-control {
    @apply border-r-0 rounded-tl-none rounded-bl-none;
}
.filter-form .custom-selectbox select {
    @apply rounded-tl-none rounded-bl-none;
}
.label-with-select > span + div {
    @apply pl-[55px];
}
.separator-with-title span {
    @apply relative px-2 font-medium bg-interface-100 text-interface-600;
}
.auto-generate-data li div {
    @apply flex w-full;
}
.auto-generate-data li div span {
    @apply font-normal text-interface-900;
}
.custom-dropdown > span + div {
    @apply h-[38px] w-full shadow-input-shadow rounded-md border border-interface-300 bg-common-white text-sm text-interface-600 placeholder:text-interface-600 focus:border-interface-500 focus:outline-none;
}
.auth-section form > div {
    @apply relative p-0 mb-4 min-h-min;
}
.listing-form > div > div {
    @apply overflow-visible !important;
}
.quote-info-list ul li div {
    @apply font-semibold text-interface-600 last-of-type:font-normal even:text-interface-900;
}
.quote-info-list {
    @apply !flex !bg-common-white !py-5 !px-4 !rounded-[15px] !cursor-pointer last-of-type:mb-0;
}
.suggested-row .quote-info-list:first-of-type {
    @apply !bg-interface-200;
}
.active-card ul li div {
    @apply text-primary-500 even:text-primary-500;
}
.custom-dropdown > div + div div div {
    @apply py-1 text-sm bg-transparent text-interface-900 hover:bg-interface-300;
}
.custom-dropdown > span + div > div + div span,
.custom-dropdown span + div > div + div > div {
    @apply hidden;
}
.table-placeholder .rdt_TableHeadRow,
.table-placeholder .bg-placeholder div {
    @apply flex items-center w-full bg-interface-100;
}
.projects-filters > div:nth-child(1) {
    @apply absolute top-[24px] right-[400px];
}
.projects-filters > div:nth-child(2) {
    @apply absolute top-[24px] right-52;
}
.quote-info-list li:last-child div {
    @apply flex-col;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    @apply !shadow-none !border-interface-300 !border-t-0;
}
/* =============
    Table
=============== */
.base-table .rdt_Table.rdt_Table {
    @apply relative w-full border border-interface-300;
}
.table-placeholder .rdt_TableCell {
    @apply w-auto;
}
.rdt_Table .rdt_TableCell {
    @apply break-normal text-interface-900;
}
.table-placeholder .rdt_TableBody {
    @apply flex flex-col;
}
.pdf-table .rdt_Table .rdt_TableCell {
    @apply items-start;
}
.table-placeholder .rdt_TableRow {
    @apply flex h-[45px] w-full animate-pulse items-center justify-center;
}
.rdt_Table .rdt_TableHead.sc-grXZZQ {
    @apply sticky top-[-1px] left-0 z-[1] shadow-sm !important;
}
.rdt_Table .rdt_TableHeadRow {
    @apply min-h-[27px] text-sm;
}
.rdt_TableCol.rdt_TableCol {
    @apply normal-case border-b-2 border-interface-300 bg-interface-100 text-interface-600;
}
.rdt_Table .rdt_TableRow {
    @apply bg-common-white even:bg-interface-50 hover:bg-primary-50;
}
.rdt_Table .rdt_TableRow:hover .arrow {
    @apply flex items-center justify-center w-full h-full p-4 bg-primary-500 text-common-white;
}
.btn-tag i {
    @apply relative mr-2 text-base;
}
.dropdown-button i {
    @apply relative left-[6px] top-[2px] text-common-black;
}
.tabList button i {
    @apply mr-2 text-base mobile:hidden;
}
.disabled-list button i {
    @apply text-interface-300;
}
.rdt_Table .actions i {
    @apply pr-2 cursor-pointer;
}
.content-placeholders-animation.pipeline-content-placeholders-animation i {
    @apply hidden;
}
.table-placeholder.table-placeholder i {
    @apply inline-block w-4 h-4 text-opacity-0 animate-pulse bg-interface-300;
}
.customize-radio .circle i {
    @apply relative top-[3px] left-[0px] hidden text-[9px] text-common-white;
}
.customize-radio.active .circle i {
    @apply block;
}
.rdt_Table .rdt_TableRow:hover .arrow i {
    @apply bg-primary-500 text-common-white;
}
.selected-row {
    @apply bg-primary-50 text-common-white;
}
.accordian-table .rdt_TableRow {
    @apply items-center !important;
}
.base-table .rdt_Table .rdt_TableRow,
.MjKnz:not(:last-of-type),
.base-table .rdt_Table:not(:last-of-type) {
    @apply border-none border-b-transparent;
}
.bg-placeholder-img img {
    @apply object-cover w-full h-full;
}
.rdt_Table .actions {
    @apply flex items-center justify-center;
}
.base-table .modal-table-loader {
    @apply absolute bottom-0;
}
/* table conditional styles */
.cell-conditional-styles {
    @apply flex items-center justify-center w-full h-full;
}
.cell-conditional-styles.cell-danger,
.cell-danger {
    @apply bg-danger-50 text-danger-500;
}
.cell-conditional-styles.cell-success,
.cell-success {
    @apply bg-success-50 text-primary-500;
}
.pdf-section .cell-success {
    @apply bg-success-50 text-common-black;
}
.base-table
    .rdt_Table
    .rdt_TableRow:hover
    .cell-conditional-styles.cell-danger {
    @apply bg-primary-50;
}
.cost-table tbody tr:hover td[id='select'],
.cost-table tbody tr:hover td[id='select'] .icon-arrow-right {
    @apply bg-primary-500 text-common-white;
}
.project-table .rdt_TableCell[data-column-id='select'],
.table-listing .rdt_TableCell[data-column-id='select'] {
    @apply px-0 py-0 cursor-pointer;
}
.add-product-listing .rdt_TableCol[data-column-id='2'],
.listing-second-column .rdt_TableCol[data-column-id='2'] {
    @apply pl-3;
}
.table-listing a {
    @apply flex items-center w-full h-full text-interface-900;
}
/* =============
    Table Placeholder
=============== */
.table-placeholder {
    @apply font-redacted;
}
.table-placeholder .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow {
    @apply font-redacted text-interface-300;
}
.table-placeholder .rdt_TableHead {
    @apply flex flex-col items-center justify-center w-full h-full;
}
.table-placeholder .rdt_TableCol {
    @apply w-auto;
}
.pdf-section .base-table .rdt_TableCol.rdt_TableCol {
    background-color: #f5f5f4 !important;
    print-color-adjust: exact !important;
}
.pdf-section .base-table .rdt_TableHead {
    background-color: #f5f5f4 !important;
    print-color-adjust: exact !important;
}
.table-placeholder
    .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol.rdt_TableCol,
.table-placeholder a,
.table-placeholder .status-loader {
    @apply border-b-0 font-redacted text-interface-300;
}
.table-placeholder .status-loader.status-loader {
    @apply text-opacity-0 bg-interface-300;
}
.table-placeholder .rdt_Table .rdt_TableCell {
    @apply animate-pulse font-redacted text-interface-300;
}
.table-placeholder.table-placeholder.table-placeholder img,
.table-placeholder.table-placeholder.table-placeholder .text-placeholder {
    @apply hidden !important;
}
.text-placeholders,
.content-placeholders-animation,
.content-placeholders-animation .status-loader.status-loader,
.text-placeholders .status-loader.status-loader,
.table-placeholder.table-placeholder .btn div span,
.table-placeholder.table-placeholder .actions {
    @apply animate-pulse font-redacted text-interface-300;
}
.separator-with-title {
    @apply relative mb-4;
}
.separator-with-title:before {
    content: '';
    @apply absolute left-0 right-0 top-[11px] h-[1px] w-full bg-interface-300;
}
.btnMenu {
    @apply fixed right-[26px] top-5 z-10 cursor-pointer mobile:right-[18px];
}
.btnMenu:before {
    content: '\2261';
    @apply text-[50px] text-primary-500;
}
.backdrop {
    @apply fixed z-[9] hidden h-full w-full bg-common-black bg-opacity-40 ipad:block;
}
.card {
    @apply p-6 mb-5 border rounded-[4px] bg-interface-100 border-interface-300;
}
.section {
    @apply px-8 py-6;
}
.manage-plots .section {
    @apply p-0 !important;
}
.manage-plots .base-table td {
    @apply py-1 !important;
}
.configuration-section form > .section {
    @apply px-8 py-6 !important;
}
.bookmark-section,
.recents-section {
    box-shadow:
        0px 0px 1px 0px rgba(9, 30, 66, 0.21),
        0px 1px 1px 0px rgba(9, 30, 66, 0.15);
    @apply relative flex p-2 border-b border-interface-300 bg-common-white even:bg-interface-50 last-of-type:border-0;
}
.bookmark-list,
.recents-list {
    @apply flex;
}
.bookmark-list li {
    @apply relative mr-3 text-xs text-interface-600;
}
.bookmark-list li:after {
    content: '/';
    @apply relative right-[-6px] last-of-type:hidden;
}
.recents-list li {
    @apply relative mr-3;
}
.recents-list li:after {
    content: '|';
    @apply absolute right-[-8px] top-0 text-interface-600 last-of-type:hidden;
}
/* setup organization -> project-slug */
.project-slug .icon-check-fill {
    @apply right-[11px] top-[30px] text-lg text-success-500;
}
.project-slug .icon-alert {
    @apply text-2xl text-danger-500;
}
.project-slug.no-label .icon-alert,
.project-slug.no-label .icon-check-fill {
    @apply top-[5px];
}
/* end of setup organization -> project-slug */
.status-circle {
    @apply relative top-0.5 mr-2 inline-block h-3 w-3 rounded-full bg-interface-200;
}
.status-circle.electricBaseload {
    @apply bg-warning-500;
}
.status-circle.electricActiveLoad {
    @apply bg-[#FFC107];
}
.status-circle.gasBaseload {
    @apply bg-[#4CAF50];
}
.rolling-12-months-table .rdt_TableRow:first-child {
    @apply font-bold;
}
.bg-placeholder {
    background-image: url(/placeholder.png);
}
.booking-calendar-section {
    @apply flex border rounded-md shadow-input-shadow h-[38px] border-interface-300;
}
.bg-placeholder-img {
    background-image: url(/image-placeholder.svg);
    @apply bg-center bg-no-repeat bg-contain bg-interface-200;
}
.auto-generate-data {
    @apply px-4;
}
.auto-generate-data li {
    @apply flex py-3 border-b border-interface-200 last:border-0;
}
.radioTabs label {
    @apply w-full py-0 text-sm font-medium text-interface-600 hover:bg-transparent hover:rounded-none;
}
.auto-generate-data li div label {
    @apply text-sm font-medium text-interface-600;
}
/*
    customize Radio
*/
.customize-radio {
    @apply relative pl-6 mb-0 cursor-pointer;
}
.customize-radio .circle {
    @apply absolute left-0 top-0.5 block h-4 w-4 rounded-full bg-common-white border border-interface-300 text-center;
}
.customize-radio .label-text {
    @apply text-sm font-normal text-interface-900;
}
.customize-radio.active .circle {
    @apply border-interface-900 bg-interface-900;
}
.signout-section {
    @apply rounded-md hover:bg-interface-100;
}
.signout-section .icon-position {
    @apply flex-col;
}
.auth-section button,
.filter-section button {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    @apply relative flex h-[42px] tracking-normal hover:tracking-normal w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-md border px-4 text-sm font-medium bg-primary-500 text-common-white outline-0 focus:ring-0;
}
button:active {
    transform: none !important;
}
.form-error {
    @apply absolute w-full text-right right-0 top-[-1px] text-[0] font-semibold tracking-normal text-[#AE2A19] !important;
}
.form-error:after {
    content: 'Required';
    @apply text-xs;
}
.form-error:before {
    content: '';
    @apply w-full h-[34px] border pointer-events-none border-[#AE2A19] absolute left-0 top-[21px] rounded-md;
}
.form-text {
    @apply h-[34px] w-full px-3 shadow-input-shadow py-[9px] bg-common-white rounded-md border border-interface-300 !important;
}
.auth-section .form-error:before,
.auth-section .form-text {
    @apply h-[38px] !important;
}
.auth-section form > div label,
.form-title.form-title label {
    @apply text-sm font-medium text-interface-900;
}
.form-text.form-text input {
    @apply w-full h-auto text-sm bg-common-white text-interface-900 placeholder:text-interface-600 focus:border-interface-500 rounded-none focus:outline-none !important;
}
/* selectbox */
.custom__selectbox {
    @apply relative w-full text-sm;
}
.custom__selectbox .custom-react-select__control.custom-react-select__control {
    @apply min-h-[34px] h-[34px] text-interface-900 shadow-none border-interface-300 rounded-md pt-0 hover:border-primary-500 placeholder:text-interface-600;
}
/*
    Size MD
*/
.custom__selectbox.custom-react-select__md
    .custom-react-select__control.custom-react-select__control {
    @apply h-[38px];
}
.custom__selectbox .custom-react-select__value-container {
    @apply top-[-1px];
}
.custom__selectbox
    .custom-react-select__value-container.custom-react-select__value-container--is-multi.css-3w2yfm-ValueContainer {
    @apply py-[1px];
}
.custom__selectbox .custom-react-select__menu-list {
    @apply scrollable max-h-[200px];
}
.switch-select .custom__selectbox .custom-react-select__menu-list {
    @apply scrollable max-h-[335px];
}
.switch-select
    .custom__selectbox
    .custom-react-select__menu-list
    .custom-react-select__option {
    @apply py-3 even:bg-interface-100 hover:bg-primary-50 focus:bg-primary-50;
}
.switch-select
    .custom__selectbox
    .custom-react-select__menu-list
    .custom-react-select__option.focused-option {
    @apply py-3 bg-primary-50;
}
.switch-select .custom-react-select__menu {
    @apply !z-[2];
}
.custom__selectbox .custom-react-select__placeholder {
    @apply overflow-hidden text-ellipsis whitespace-nowrap text-interface-600;
}
.fixed-label .custom-react-select__input-container,
.fixed-label .custom-react-select__placeholder,
.fixed-label .custom-react-select__single-value {
    @apply pl-[150px] pt-[3px];
}
/* selectbox disabled */
.custom__selectbox.disabled,
.custom__selectbox .custom-react-select--is-disabled.css-3iigni-container,
.custom-react-select__control.custom-react-select__control--is-disabled.css-16xfy0z-control,
.custom__selectbox
    .custom-react-select__control.custom-react-select__control--is-disabled
    .custom-react-select__placeholder {
    @apply !cursor-not-allowed pointer-events-auto border-interface-300 bg-interface-50 text-interface-400 hover:border-interface-300;
}
.custom__selectbox.disabled .css-13cymwt-control {
    @apply bg-interface-50;
}
.custom__selectbox
    .custom-react-select__indicators
    .custom-react-select__indicator-separator {
    @apply hidden;
}
/* selectbox error */
.custom__selectbox.hasError {
    @apply border rounded-md border-danger-500;
}
.custom__selectbox.hasError
    .custom-react-select__control.custom-react-select__control {
    @apply border-none;
}
/* selectbox dropdown */
.custom__selectbox .custom-react-select__option.custom-react-select__option {
    @apply hover:bg-primary-50 focus:bg-primary-50 hover:text-interface-950;
}
.focused-option {
    @apply bg-primary-50 text-interface-950;
}
.custom__selectbox
    .custom-react-select__option.custom-react-select__option.custom-react-select__option--is-selected.custom-react-select__option--is-selected,
.custom__selectbox .custom-react-select__option.selected-option {
    @apply !bg-primary-500 text-common-white hover:!bg-primary-500 hover:!text-common-white;
}
.custom__selectbox
    .custom-react-select__control.custom-react-select__control
    .custom-react-select__multi-value {
    @apply rounded bg-interface-200;
}
.custom__selectbox
    .custom-react-select__control.custom-react-select__control
    .custom-react-select__multi-value
    .custom-react-select__multi-value__remove {
    @apply hover:bg-transparent hover:text-danger-500;
}
.custom__selectbox
    .custom-react-select__indicator.custom-react-select__clear-indicator {
    @apply hidden;
}
.custom__selectbox.multiSelect .custom-react-select__control {
    @apply overflow-y-scroll scrollable max-h-[34px];
}
.country-multi-select
    .custom__selectbox.multiSelect
    .custom-react-select__control {
    @apply overflow-y-scroll scrollable max-h-[65px] h-auto;
}
.custom__selectbox.multiSelect .custom-react-select__indicators {
    @apply items-start !important;
}
.custom-react-select__indicator.custom-react-select__dropdown-indicator {
    @apply h-7;
}
.custom-react-select__md
    .custom-react-select__indicator.custom-react-select__dropdown-indicator {
    @apply h-9;
}
.config-legacy-select .custom-react-select__menu {
    @apply z-10 !important;
}
.modal-header-menu .custom__selectbox .custom-react-select__menu {
    @apply z-10;
}
/* menu width xl */
.menu-xl .custom__selectbox .custom-react-select__menu {
    @apply w-[300px];
}
/* menu width lg */
.menu-lg .custom__selectbox .custom-react-select__menu {
    @apply w-[260px];
}
/* menu width md */
.menu-md .custom__selectbox .custom-react-select__menu {
    @apply w-[200px];
}
/* menu width sm */
.menu-sm .custom__selectbox .custom-react-select__menu {
    @apply w-[150px];
}
input[type='date'] {
    @apply relative uppercase;
}
input[type='date']:after {
    content: '';
    background-image: url(/calendar.svg);
    @apply absolute right-[10px] w-5 pointer-events-none h-5 bg-cover bg-common-white;
}
/* tabbed selectbox  */
.selectbox.tabbed .css-13cymwt-control,
.selectbox.tabbed .css-t3ipsp-control {
    @apply rounded-md rounded-tr-none rounded-br-none;
}
.fixed-label {
    @apply relative;
}
.fixed-label .custom-select-label {
    @apply absolute top-[6px] left-[1.5px] z-10 flex items-center h-[22px] pl-[12px] pr-1 overflow-hidden text-interface-600 bg-common-white;
}
.filter-section form {
    @apply flex gap-2;
}
.form-control.form-control,
.form-control-text.form-control-text {
    @apply h-[34px] w-full appearance-none rounded-md border border-interface-300 bg-common-white px-2 text-sm leading-none text-interface-900 placeholder:text-interface-600 focus:border-primary-500 hover:border-primary-500 focus:outline-none !important;
}
.form-control-text.form-control-text {
    @apply p-0 !important;
}
.custom-react-select__indicator svg {
    @apply relative -top-[4px];
}
.custom-react-select__md .custom-react-select__indicator svg {
    @apply relative top-[0px];
}
.menu-top .custom__selectbox .custom-react-select__menu {
    @apply top-auto bottom-[32px];
}
.menu-top .custom__selectbox .custom-react-select__menu-list {
    @apply h-auto max-h-[120px] scrollable;
}
.menu-right .custom__selectbox .custom-react-select__menu {
    @apply right-0;
}
.project-hardware-set .custom__selectbox .custom-react-select__menu {
    @apply z-[90];
}
.project-hardware-set .custom__selectbox .custom-react-select__menu-list {
    @apply !max-h-[90px] border border-interface-300;
}
select.form-control.form-control {
    background: url(/dropdown.svg) no-repeat;
    background-position: right 12px center;
    @apply pr-[28px] border border-interface-300 !important;
}
.filter-section form > div button {
    @apply bg-interface-200 h-[34px] text-interface-600 font-medium text-sm;
}
.filter-section.filter-section .form-error {
    @apply top-0 mt-0 text-[0px] !important;
}
.filter-section.filter-section .form-error:before {
    @apply top-0 h-[34px];
}
.project-filter form > div:first-child {
    @apply w-[79px] 3xl:w-full;
}
.project-filter form > div:nth-child(2) {
    @apply w-[116px] 3xl:w-full;
}
.project-filter form > div:nth-child(3) {
    @apply w-[107px] 3xl:w-full;
}
.project-filter form > div:nth-child(4) {
    @apply w-[130px] 3xl:w-full;
}
.project-filter form > div:last-child {
    @apply w-[56px];
}
.form-fields.form-fields form > div > div {
    @apply px-2 py-[6px] mb-0;
}
.pdf-table .rdt_TableCol .rdt_TableCol_Sortable > div {
    @apply whitespace-normal;
}
.filterby-select .css-b62m3t-container[id='productType'] {
    @apply w-[162px];
}
.filterby-select .css-b62m3t-container[id='filter-by'] {
    @apply w-[157px];
}
.textarea-section.textarea-section {
    @apply h-[194px];
}
.project-filter.contact-filter form > div:first-child {
    @apply w-[143px];
}
.ql-toolbar.ql-snow.ql-toolbar.ql-snow,
.ql-container.ql-snow.ql-container.ql-snow {
    @apply border-0;
}
.ql-toolbar.ql-snow.ql-toolbar.ql-snow,
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content .ck-toolbar {
    @apply border border-b rounded-md rounded-bl-none rounded-br-none bg-interface-50 border-interface-300 font-theme;
}
.ck.ck-reset_all {
    @apply !border-b !border-interface-300 !border-solid;
}
.ql-snow.ql-snow .ql-editor.ql-editor,
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    @apply p-3 !border !border-t-0 !rounded-md !rounded-tl-none !rounded-tr-none bg-common-white border-interface-300;
}
.ql-container.ql-container {
    @apply font-theme h-[106px];
}
.content-section .ql-container.ql-container,
.project-content-settings .ck.ck-editor__main,
.project-content-settings .ql-container.ql-container {
    @apply h-[calc(100vh-273px)];
}
.project-content-settings .ck.ck-editor__main {
    @apply overflow-y-auto;
}
.editor-data p {
    @apply mb-4 text-base;
}
.ck.ck-content.editor-data p {
    @apply mb-4 text-base text-common-black;
}
.ck-editor__editable .ck-list-bogus-paragraph {
    @apply !inline leading-5;
}
.react-editor.editor-error .ck-editor {
    @apply border-danger-500 border overflow-hidden rounded-md border-solid !important;
}
.ck.ck-content {
    @apply h-full;
}
.ql-snow.ql-snow .ql-editor.ql-editor p,
.ck.ck-content p {
    @apply mb-4 text-[14px] leading-5 text-interface-900;
}
.ql-editor.editor-data p {
    @apply mb-4;
}
.ql-snow.ql-snow .ql-editor.ql-editor p {
    @apply mb-4 text-sm text-interface-900;
}
.pdf-section .ql-editor {
    @apply p-0;
}
.ql-snow.ql-snow .ql-editor h1,
.ql-editor.editor-data h1,
.ck.ck-content h1 {
    @apply mb-4 text-3xl ipad:text-2xl mobile:text-xl;
}
.ql-snow.ql-snow .ql-editor h2,
.ql-editor.editor-data h2,
.ck.ck-content h2 {
    @apply mb-4 text-2xl ipad:text-xl mobile:text-base;
}
.ql-snow.ql-snow .ql-editor h3,
.ck.ck-content h3 {
    @apply mb-4 text-xl mobile:text-base;
}
.ql-snow.ql-snow .ql-editor h4,
.ck.ck-content h4 {
    @apply mb-4 text-lg;
}
.ql-snow.ql-snow .ql-editor h5,
.ck.ck-content h5 {
    @apply mb-4 text-base mobile:text-sm;
}
.ql-snow.ql-snow .ql-editor h6,
.ck.ck-content h6 {
    @apply mb-4 text-sm;
}
.configuration-section .ql-snow.ql-snow .ql-editor h1,
.configuration-section .ql-snow.ql-snow .ql-editor h2,
.configuration-section .ql-snow.ql-snow .ql-editor h3,
.configuration-section .ql-snow.ql-snow .ql-editor h4,
.configuration-section .ql-snow.ql-snow .ql-editor h5,
.configuration-section .ql-snow.ql-snow .ql-editor h6,
.configuration-section .ql-snow.ql-snow .ql-editor p {
    @apply mb-0;
}
.ql-snow.ql-snow .ql-editor.ql-editor p:last-child {
    @apply mb-0;
}
.configuration-section .ql-snow.ql-snow .ql-editor a {
    @apply text-interface-900;
}
.currency-table .last-listing td .custom-react-select__menu {
    @apply absolute top-[38px] bottom-auto !important;
}
.currency-table tr:nth-last-child(-n + 3) td .custom-react-select__menu {
    @apply bottom-[36px] top-auto;
}
/* .header-footer-table tr:nth-child(n + 7) td .custom-react-select__menu {
    @apply absolute  top-[-90px];
}*/
.hardeware-sets-listing tbody tr td,
.house-type-listing tbody tr td {
    @apply align-middle !important;
}
.product-image-table-pdf .rdt_TableHeadRow th:first-of-type {
    @apply px-0 !important;
}
.product-image-table-pdf tr td:first-of-type {
    @apply pr-[2px] pl-1 !important;
}
.hardeware-sets-listing .custom-table-form thead th:nth-child(4),
.harward-sets-products .custom-table-form thead th:nth-child(3) {
    @apply text-center;
}
.hardeware-sets-listing .custom-table-form thead th:nth-child(5),
.hardeware-sets-listing .custom-table-form tbody td:nth-child(5),
.harward-sets-products .custom-table-form thead th:nth-child(4),
.harward-sets-products .custom-table-form tbody td:nth-child(4) {
    @apply pl-5;
}
.editor-data ol {
    @apply mb-4 max-w-[80%] pl-2 list-decimal;
}
.editor-data ul {
    @apply pl-6 list-disc max-w-[80%];
}
.pdf-section .editor-data ol,
.pdf-section .editor-data ul {
    @apply max-w-full;
}
.ql-editor.editor-data ol,
.ql-editor.editor-data ul,
.ck.ck-content ul,
.ck.ck-content ol {
    @apply w-full pl-2 mb-4;
}
.editor-data li {
    @apply mb-2;
}
.ql-snow.ql-snow ul,
.ql-snow.ql-snow ol,
.ck.ck-content ul,
.ck.ck-content ol {
    @apply pl-0 mb-5 text-sm;
}
.extra-info li {
    list-style: none;
}
.ck.ck-content ul {
    @apply pl-4;
}
.ck.ck-content ol {
    @apply mt-2;
}
.ck.ck-content ol li ol li {
    @apply pl-3 list-[lower-alpha];
}
.ck.ck-content ol li ol li ol li {
    @apply list-[lower-roman];
}
.ck.ck-content ol li ol li ol li ol li {
    @apply list-decimal;
}
.pdf-section .editor-data ol li {
    @apply text-base text-common-black;
}
.ck.ck-content ol li ul li {
    @apply !list-disc;
}
.ck.ck-content ol li ul li ul li {
    list-style-type: circle !important;
}
.ck.ck-content ol li ul li ul li ul li {
    list-style-type: square !important;
}
.ql-snow.ql-snow ul li span,
.ql-snow.ql-snow ol li span,
.ck.ck-content ul li span,
.ck.ck-content ol li span {
    @apply mb-4 !block;
}
.ck.ck-content ol li p {
    @apply contents;
}
.form-fields form {
    @apply w-full;
}
.page-header {
    @apply relative px-8 py-6 bg-interface-50 shadow-header-shadow;
}
.page-actions {
    @apply flex gap-[6px] px-8 py-6 border-b-8 border-interface-200 bg-common-white;
}
/* hardware set */
.hardware-page .footer,
.fixed-footer {
    box-shadow:
        0px -1px 3px 0px rgba(0, 0, 0, 0.1),
        0px -1px 2px 0px rgba(0, 0, 0, 0.06);
}
.fixed-footer {
    @apply fixed bottom-0 w-[calc(100%-120px)] p-4 bg-interface-50 z-[9];
}
.fixed-footer.project-footer {
    @apply shadow-table-footer-shadow;
}
.harward-sets-products .rdt_Table.rdt_Table {
    @apply border-b-0;
}
.form-fields-separator {
    @apply pb-6 mb-2 border-b border-interface-300;
}
/* table radio styles */
.radio-table-row {
    @apply w-full h-full p-3 cursor-pointer;
}
/* cost summary footer */
.cost-summary-table .rdt_Table .rdt_TableBody > .rdt_TableRow:last-child {
    box-shadow:
        0px -1px 3px 0px rgba(0, 0, 0, 0.1),
        0px -1px 2px 0px rgba(0, 0, 0, 0.06);
    @apply absolute bottom-0 bg-interface-50;
}
.cost-summary-table .rdt_Table .rdt_TableBody {
    @apply 3xl:h-[calc(100vh-157px)] h-[calc(100vh-152px)] pb-[52px] overflow-y-auto;
}
.flag-dropdown {
    @apply hidden;
}
/* radi button */
.custom-radio-field .checkmark,
.custom-checkbox-field .checkmark {
    @apply absolute top-0 left-0 flex items-center justify-center w-4 h-4 border rounded-full bg-common-white border-interface-300;
}
.custom-checkbox-field .checkmark {
    @apply rounded-[4px];
}
.custom-radio-field:hover input ~ .checkmark,
.custom-checkbox-field:hover input ~ .checkmark {
    @apply bg-interface-50;
}
.custom-radio-field .checkmark:after,
.custom-checkbox-field .checkmark:after {
    content: '';
    @apply hidden bg-common-white rounded-full h-[6px] w-[6px] top-1 left-1;
}
.custom-checkbox-field .checkmark:after {
    content: '';
    background: url(/check.svg);
    @apply w-[11px] top-[2px] h-[9px] bg-cover hidden;
}
.custom-radio-field input:checked ~ .checkmark,
.custom-checkbox-field input:checked ~ .checkmark {
    @apply bg-primary-500 border-primary-500;
}
.disabled {
    @apply opacity-50 cursor-not-allowed;
}
.disabled .custom-radio-field,
.disabled .custom-checkbox-field {
    @apply pointer-events-none;
}
.disabled .custom-radio-field input:checked ~ .checkmark,
.disabled .custom-checkbox-field input:checked ~ .checkmark {
    @apply bg-interface-600 border-interface-600;
}
.custom-radio-field input:checked ~ .checkmark:after,
.custom-checkbox-field input:checked ~ .checkmark:after {
    @apply block;
}
/*.custom-radio-field .checkmark:after,
.custom-checkbox-field .checkmark:after {
    @apply bg-common-white rounded-full h-[6px] w-[6px] top-1 left-1;
}*/
.fixed-header {
    @apply fixed top-0 w-[calc(100%-120px)] z-[99] right-0 border-l border-interface-300;
}
/* Table Radio */
.rdt_TableBody .rdt_TableRow .table-radio input {
    @apply w-[42px] h-[57px] appearance-none z-[1] top-[-30px] left-[-13px] absolute;
}
.rdt_TableBody .rdt_TableRow .table-radio input + span {
    @apply top-[-12px];
}
/* date picker icon */
input[type='date' i]::-webkit-calendar-picker-indicator {
    background-image: url('/calendar.svg');
}
.timeline {
    @apply relative;
}
.timeline:after {
    content: '';
    @apply w-0.5 h-full absolute min-h-[calc(100vh-110px)] top-0 left-8 bg-interface-400;
}
.btn.disabled {
    @apply pointer-events-none bg-interface-100 border-interface-300 text-interface-400;
}
/* 
    ================== 
    PDF Templates CSS
   ================== 
*/
.pdf-section {
    @apply mt-10 text-base !text-common-black;
}
.pdf-section.with-separator {
    @apply pb-6 border-b border-interface-600;
}
/* .pdf-section .base-table .rdt_Table {
    @apply border-0 !important;
} */
/* .pdf-section .base-table .rdt_TableBody .rdt_TableRow:last-child {
    @apply print:mb-10;
} */
.editor-data {
    @apply my-6;
}
.editor-data li::marker {
    @apply font-medium;
}
/* RadioTabs */
.radioTabs {
    @apply shadow-sm border w-full relative border-interface-300 bg-common-white m-0 hover:bg-interface-50 first:border-r-0 last:border-l-0 first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md overflow-hidden h-[34px] flex items-center justify-center;
}
.radioTabs input[type='radio'] {
    @apply hidden;
}
.radioTabs input[type='radio']:checked ~ span {
    @apply w-full border border-primary-500 bg-primary-500 text-common-white;
}
.radioTabs .radioTabsLabel {
    @apply flex justify-center;
}
.radioTabs .tabLabel {
    @apply w-full flex justify-center cursor-pointer h-[34px] items-center label-text px-[13px] overflow-hidden whitespace-nowrap;
}
.radioTabs.disabled {
    @apply opacity-50 pointer-events-none;
}
.custom__selectbox.disabled {
    @apply text-sm pointer-events-none border-interface-300 hover:border-interface-300 bg-interface-50 text-interface-400 placeholder:text-interface-400;
}
.noSpace {
    @apply mb-0;
}
.Toastify__toast--success.Toastify__toast--success {
    @apply border border-success-600 bg-success-400 text-common-white;
}
.Toastify__toast--error.Toastify__toast--error {
    @apply border border-danger-600 bg-danger-500 text-common-white;
}
.Toastify__toast--success svg path,
.Toastify__toast--error svg path {
    @apply fill-common-white;
}
.Toastify__close-button--light.Toastify__close-button--light {
    @apply opacity-100;
}
.Toastify__progress-bar--success.Toastify__progress-bar--success {
    @apply bg-success-300;
}
.Toastify__progress-bar--danger.Toastify__progress-bar--danger {
    @apply bg-danger-600 bg-none;
}
/* Infinite-scroll table height */
/* .cost-table .infinite-scroll-component {
    @apply h-[calc(100vh-156px)] !important;
} */
.add-product-listing .infinite-scroll-component {
    @apply h-[433px] !important;
}
.base-table .sc-dmBjiZ {
    @apply overflow-visible;
}
.base-table .infinite-scroll-component div:nth-of-type(1) {
    @apply overflow-visible;
}
/* .currency-table .infinite-scroll-component,
.header-footer-table .infinite-scroll-component,
.contact-types-table .infinite-scroll-component {
    @apply h-[calc(100vh-224px)] !important;
} */
.door-summary-table .infinite-scroll-component {
    @apply h-[calc(100vh-298px)] !important;
}
/* .product-global-table .infinite-scroll-component {
    @apply h-[calc(100vh-330px)] !important;
} */
.Toastify__toast-container--bottom-right.Toastify__toast-container--bottom-right {
    @apply bottom-8;
}
/* Tooltip */
.tooltip {
    @apply relative grid text-common-black;
}
.tooltip-content-top {
    transform: translateY(-100%);
}
.tooltip-content-bottom {
    transform: translateY(100%);
}
.tooltip-content {
    @apply invisible absolute -top-[10px] z-[99] w-max min-w-[auto] max-w-[290px] rounded-md bg-interface-900 px-2 py-2 text-sm text-common-white opacity-0 shadow-2xl;
}
.tooltip-content-right {
    @apply max-w-[600px] -top-[7px];
}
.tooltip-content::before {
    content: '';
    @apply absolute aspect-square w-[10px] bg-interface-900;
}
.tooltip-content-top::before {
    transform: translateX(45%) rotate(45deg);
    @apply -bottom-[5px] left-2/4;
}
.tooltip-content-right::before {
    transform: translateX(-45%) rotate(45deg);
    @apply top-[12px] left-0;
}
.tooltip-content-bottom::before {
    transform: translateX(-50%) rotate(-45deg);
    @apply -top-[5px] left-2/4;
}
.accordian-qty-tooltip .tooltip-content {
    @apply max-w-[415px];
}
.tooltip:hover .tooltip-content {
    transition: opacity 0.1s ease-in;
    @apply visible opacity-100;
}
/* flash loader */
.flash-loader,
.flash-loader:before,
.flash-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation: bblFadInOut 1.8s infinite ease-in-out;
    animation-fill-mode: both;
}
.flash-loader {
    color: #9ca3af;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}
.flash-loader:before,
.flash-loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.flash-loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}
.flash-loader:after {
    left: 3.5em;
}

@keyframes bblFadInOut {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
.active-card,
.suggested-row .quote-info-list.active-card:first-of-type {
    background-color: rgb(236, 244, 255) !important;
    @apply border-primary-500;
}
.accordian-qty-tooltip .tooltip-content::before {
    @apply -left-[4px] rotate-45 !important;
}
.select-product .custom__selectbox {
    @apply w-[30%];
}
.pdf-section,
.pdf-table {
    @apply font-print print:font-print !important;
}
.door-summary-table-row .rdt_TableBody .rdt_TableCell {
    @apply py-1;
}
.disable-drag * {
    -webkit-user-drag: none;
    user-select: none;
    pointer-events: auto;
}
.ck-powered-by {
    @apply hidden;
}
.pre-tag {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    white-space: pre-wrap;
}

@tailwind utilities;
