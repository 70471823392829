@font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot?uo3wb5');
    src: url('/fonts/icomoon.eot?uo3wb5#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?uo3wb5') format('truetype'),
        url('/fonts/icomoon.woff?uo3wb5') format('woff'),
        url('/fonts/icomoon.svg?uo3wb5#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-shrink:before {
    content: '\e924';
}
.icon-expand:before {
    content: '\e925';
}
.icon-compare:before {
    content: '\e900';
}
.icon-external-link:before {
    content: '\e901';
}
.icon-door-open:before {
    content: '\e902';
}
.icon-file:before {
    content: '\e903';
}
.icon-arrow-up:before {
    content: '\e904';
}
.icon-sort:before {
    content: '\e905';
}
.icon-menu:before {
    content: '\e9c9';
}
.icon-link:before {
    content: '\e9c7';
}
.icon-dash-fill:before {
    content: '\e906';
}
.icon-move-down:before {
    content: '\e978';
}
.icon-move-up:before {
    content: '\e9c2';
}
.icon-download-solid:before {
    content: '\e9bb';
}
.icon-switch-filled:before {
    content: '\e9af';
}
.icon-card-image:before {
    content: '\e92c';
}
.icon-info:before {
    content: '\e932';
}
.icon-info1:before {
    content: '\e973';
}
.icon-close:before {
    content: '\e974';
}
.icon-unlock:before {
    content: '\e907';
}
.icon-lock:before {
    content: '\e908';
}
.icon-delete:before {
    content: '\e909';
}
.icon-copy:before {
    content: '\e90a';
}
.icon-add:before {
    content: '\e90b';
}
.icon-add-item:before {
    content: '\e90c';
}
.icon-arrow-down:before {
    content: '\e90d';
}
.icon-arrow-left:before {
    content: '\e90e';
}
.icon-arrow-right:before {
    content: '\e90f';
}
.icon-backlog:before {
    content: '\e910';
}
.icon-branches:before {
    content: '\e911';
}
.icon-building:before {
    content: '\e912';
}
.icon-calendar:before {
    content: '\e913';
}
.icon-check:before {
    content: '\e914';
}
.icon-check-circle:before {
    content: '\e915';
}
.icon-close1:before {
    content: '\e916';
}
.icon-download:before {
    content: '\e917';
}
.icon-edit:before {
    content: '\e918';
}
.icon-folder:before {
    content: '\e919';
}
.icon-full-screen:before {
    content: '\e91a';
}
.icon-grid:before {
    content: '\e91b';
}
.icon-hex:before {
    content: '\e91c';
}
.icon-image:before {
    content: '\e91d';
}
.icon-logout:before {
    content: '\e91e';
}
.icon-refresh:before {
    content: '\e91f';
}
.icon-search:before {
    content: '\e920';
}
.icon-settings:before {
    content: '\e921';
}
.icon-trash:before {
    content: '\e922';
}
.icon-users:before {
    content: '\e923';
}
.icon-address-book:before {
    content: '\e944';
}
.icon-prev:before {
    content: '\e98c';
}
.icon-next:before {
    content: '\e9c1';
}
